import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "deposit"
};
const _hoisted_2 = {
  class: "deposit-head"
};
const _hoisted_3 = {
  class: "deposit-content"
};
const _hoisted_4 = {
  class: "deposit-price__item mb-12"
};
const _hoisted_5 = {
  class: "deposit-price__item"
};
const _hoisted_6 = {
  class: "deposit-amount__label"
};
const _hoisted_7 = {
  class: "deposit-amount__tips"
};
const _hoisted_8 = {
  class: "booking-confirm__box"
};
const _hoisted_9 = {
  class: "booking-confirm__content"
};
const _hoisted_10 = {
  class: "booking-confirm__btns"
};
const _hoisted_11 = {
  class: "booking-confirm__box"
};
const _hoisted_12 = {
  class: "booking-confirm__content"
};
const _hoisted_13 = {
  class: "booking-confirm__btns"
};
import { router } from '@/router';
import { Recharge } from '@/api';
import { closeToast, showLoadingToast, showToast } from 'vant';
import { computed, ref } from 'vue';
import { useUserStore } from '@/store/user';
export default {
  __name: 'Deposit',
  setup(__props) {
    const userStore = useUserStore();
    const amount = ref(10000);
    const showConfirm = ref(false);
    const showSuccessMsg = ref(false);
    const onClickLeft = () => {
      router.back();
    };
    userStore.updateWallet();
    const wallet = computed(() => userStore.wallet);
    const _Recharge = async () => {
      if (amount.value < 100) {
        showConfirm.value = true;
        return false;
      }
      showLoadingToast();
      Recharge({
        amount: amount.value
      }).then(({
        code,
        msg
      }) => {
        if (code === 200) {
          closeToast();
          showSuccessMsg.value = true;
        } else {
          showToast(msg);
        }
      });
    };
    return (_ctx, _cache) => {
      const _component_van_nav_bar = _resolveComponent("van-nav-bar");
      const _component_van_overlay = _resolveComponent("van-overlay");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_nav_bar, {
        class: "nav-transparent__bg",
        fixed: "",
        title: _ctx.$t('Deposit'),
        "left-arrow": "",
        border: false,
        onClickLeft: onClickLeft
      }, null, 8, ["title"]), _createElementVNode("div", _hoisted_2, [_createElementVNode("p", null, _toDisplayString(_ctx.$t('Account')), 1), _createElementVNode("p", null, "₹ " + _toDisplayString(wallet.value.balance), 1)]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", {
        class: _normalizeClass({
          'active': amount.value == 10000
        }),
        onClick: _cache[0] || (_cache[0] = $event => amount.value = 10000)
      }, "10000", 2), _createElementVNode("div", {
        class: _normalizeClass({
          'active': amount.value == 20000
        }),
        onClick: _cache[1] || (_cache[1] = $event => amount.value = 20000)
      }, "20000", 2), _createElementVNode("div", {
        class: _normalizeClass({
          'active': amount.value == 30000
        }),
        onClick: _cache[2] || (_cache[2] = $event => amount.value = 30000)
      }, "30000", 2), _createElementVNode("div", {
        class: _normalizeClass({
          'active': amount.value == 50000
        }),
        onClick: _cache[3] || (_cache[3] = $event => amount.value = 50000)
      }, "50000", 2)]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", {
        class: _normalizeClass({
          'active': amount.value == 80000
        }),
        onClick: _cache[4] || (_cache[4] = $event => amount.value = 80000)
      }, "80000", 2), _createElementVNode("div", {
        class: _normalizeClass({
          'active': amount.value == 100000
        }),
        onClick: _cache[5] || (_cache[5] = $event => amount.value = 100000)
      }, "100000", 2), _createElementVNode("div", {
        class: _normalizeClass({
          'active': amount.value == 200000
        }),
        onClick: _cache[6] || (_cache[6] = $event => amount.value = 200000)
      }, "200000", 2), _createElementVNode("div", {
        class: _normalizeClass({
          'active': amount.value == 500000
        }),
        onClick: _cache[7] || (_cache[7] = $event => amount.value = 500000)
      }, "500000", 2)]), _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('Recharge amount')), 1), _withDirectives(_createElementVNode("input", {
        type: "number",
        class: "deposit-amount__input",
        "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => amount.value = $event)
      }, null, 512), [[_vModelText, amount.value]]), _createElementVNode("div", {
        class: "deposit-amount__btn",
        onClick: _cache[9] || (_cache[9] = $event => _Recharge()),
        style: _normalizeStyle({
          opacity: amount.value >= 100 ? 1 : .3
        })
      }, "Deposit", 4), _createElementVNode("div", _hoisted_7, [_createElementVNode("p", null, _toDisplayString(_ctx.$t('Recharge rules')), 1), _createElementVNode("p", null, "1 • " + _toDisplayString(_ctx.$t('Minimum recharge amount ₹ 10000.00;')), 1), _createElementVNode("p", null, "2 • " + _toDisplayString(_ctx.$t('Each time you recharge, you must contact customer service to obtain the latest merchant account. If the amount is lost due to failure to confirm, the user shall be responsible for the loss;')), 1), _createElementVNode("p", null, "3 • " + _toDisplayString(_ctx.$t('Recharge working hours: 10:00am-10:00pm;')), 1)])]), _createVNode(_component_van_overlay, {
        show: showConfirm.value
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, [_createElementVNode("p", null, _toDisplayString(_ctx.$t('System message')), 1), _createElementVNode("p", null, _toDisplayString(_ctx.$t('Minimum recharge amount ₹ 10000.00;')), 1), _createElementVNode("div", _hoisted_10, [_createElementVNode("div", {
          onClick: _cache[10] || (_cache[10] = $event => showConfirm.value = false)
        }, _toDisplayString(_ctx.$t('Confirm')), 1)])])])]),
        _: 1
      }, 8, ["show"]), _createVNode(_component_van_overlay, {
        show: showSuccessMsg.value
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_11, [_createElementVNode("div", _hoisted_12, [_createElementVNode("p", null, _toDisplayString(_ctx.$t('System message')), 1), _createElementVNode("p", null, _toDisplayString(_ctx.$t('Please contact your dedicated manager, Get exclusive recharge channel')), 1), _createElementVNode("div", _hoisted_13, [_createElementVNode("div", {
          onClick: _cache[11] || (_cache[11] = $event => showSuccessMsg.value = false)
        }, _toDisplayString(_ctx.$t('Confirm')), 1)])])])]),
        _: 1
      }, 8, ["show"])]);
    };
  }
};